$hero-slider-title-font-family           : $font-headline !default;
$hero-slider-title-color                 : color(white) !default;
$hero-slider-title-font-size--mobile     : 40px !default;
$hero-slider-title-line-height--mobile   : 46px !default;
$hero-slider-title-font-size--desktop    : 80px !default;
$hero-slider-title-line-height--desktop  : 85px !default;
$hero-slider-title-margin--mobile        : 0 0 spacing(m) 0 !default;
$hero-slider-title-margin--desktop       : 0 spacing(xxl) * 2 spacing(l) spacing(xxl) * 2 !default;

$hero-slider-arrows-bg-color             : color(white) !default;
$hero-slider-arrows-position             : calc(100% / 2) !default;
$hero-slider-arrows-size                 : 40px !default;
$hero-slider-arrows-font-size            : 22px !default;
$hero-slider-arrows-color                : color(base) !default;
$hero-slider-arrows-prev-border-radius   : 0 border-radius(s) border-radius(s) 0 !default;
$hero-slider-arrows-next-border-radius   : border-radius(s) 0 0 border-radius(s) !default;

$hero-slider-caption-max-width--mobile   : 250px !default;
$hero-slider-caption-max-width--desktop  : 700px !default;

$hero-slider-link-margin                 : 0 spacing(xxl) * 2 spacing(l) spacing(xxl) * 2 !default;

$hero-slider-bullets-margin-top--mobile  : spacing(xxs) !default;
$hero-slider-bullets-margin-top--desktop : 22px !default;
$hero-slider-bullets-width--mobile       : 40px !default;
$hero-slider-bullets-width--desktop      : 100px !default;
$hero-slider-bullets-height              : 4px !default;
$hero-slider-bullets-margin              : spacing(xxxs) !default;
$hero-slider-bullets-active-bg-color     : color(red) !default;

.c-hero-slider {
    width       : calc(100% + 40px);
    position    : relative;
    margin-left : -20px;

    @media only screen and (min-width : 1024px) {
        width       : calc(100% + 120px);
        margin-left : -60px;
    }

    .glider {
        scrollbar-width : none;

        &::-webkit-scrollbar {
            display : none;
        }
    }

    .glider-track > div {
        margin-right : 0;
    }

    .c-hero-slider__item {
        height      : auto !important;
        display     : flex;
        align-items : flex-end;

        @include mq($from : desktop) {
            flex-direction : column;
        }

        iframe {
           height: 300px;
            width: 100%;
            @include mq($from : desktop) {
                height: 600px;
            }
        }
    }

    .c-hero-slider__title {
        font-family : $hero-slider-title-font-family;
        font-size   : $hero-slider-title-font-size--mobile;
        line-height: $hero-slider-title-line-height--mobile;
        color       : $hero-slider-title-color;
        margin      : $hero-slider-title-margin--mobile;
        text-align  : center;

        @include mq($from : desktop) {
            font-size : $hero-slider-title-font-size--desktop;
            margin    : $hero-slider-title-margin--desktop;
            line-height: $hero-slider-title-line-height--desktop;
        }
    }

    .c-hero-slider__arrows {
        background-color : $hero-slider-arrows-bg-color;
        border           : none;
        position         : absolute;
        top              : $hero-slider-arrows-position;
        transform        : translateY(-100%);
        cursor           : pointer;
        width            : $hero-slider-arrows-size;
        height           : $hero-slider-arrows-size;
        display          : flex;
        justify-content  : center;
        align-items      : center;
        font-size        : $hero-slider-arrows-font-size;
        color            : $hero-slider-arrows-color;

        &.c-hero-slider__arrows--prev {
            border-radius : $hero-slider-arrows-prev-border-radius;
        }

        &.c-hero-slider__arrows--next {
            right         : 0;
            border-radius : $hero-slider-arrows-next-border-radius;
        }

        &.disabled {
            display : none;
        }

        .c-icon:before {
            margin : 0;
        }
    }

    .c-hero-slider__caption {
        position       : absolute;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        max-width      : $hero-slider-caption-max-width--mobile;


        @include mq($from : desktop) {
            align-items : flex-start;
            max-width   : $hero-slider-caption-max-width--desktop;

            &.c-hero-slider__caption--left {
                align-self : flex-start;
            }

            &.c-hero-slider__caption--center {
                align-self : center;
            }

            &.c-hero-slider__caption--right {
                align-self : flex-end;
            }
        }
    }

    .c-hero-slider__link {
        margin : $hero-slider-link-margin;

        @include mq($from : desktop) {
            &.c-hero-slider__link--left {
                align-self : flex-start;
            }

            &.c-hero-slider__link--center {
                align-self : center;
            }

            &.c-hero-slider__link--right {
                align-self : flex-end;
            }
        }
    }

    .c-hero-slider__bullets {
        margin-top : $hero-slider-bullets-margin-top--mobile;

        @include mq($from : desktop) {
            margin-top : $hero-slider-bullets-margin-top--desktop;
        }

        button {
            width            : $hero-slider-bullets-width--mobile;
            height           : $hero-slider-bullets-height;
            border-radius    : 0;
            margin           : $hero-slider-bullets-margin;
            background-color : #AA967875;

            @include mq($from : desktop) {
                width : $hero-slider-bullets-width--desktop;
            }

            &.active {
                background-color : $hero-slider-bullets-active-bg-color;
                box-shadow       : none;
                border           : none;
            }

            &:hover {
                border : none;
            }
        }
    }
}
